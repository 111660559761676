<template>
  
    <div class="col-md-12">
        <div class="card col-md-6">
            <h5 class="card-header">Form Validation Example</h5>
            <div class="card-body">
                <Form  :validation-schema="schema" v-slot="{ errors }">
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Title </label>
                            <Field name="title" as="select" class="form-control"      >
                                <option value=""></option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                            </Field>
                            <div class="invalid-feedback" v-html="errors.title"></div>
                        </div>
                        <div class="form-group col-5">
                            <label>First Name <span class="required">(Required)</span></label>
                            <Field name="firstName" type="text" class="form-control" v-model = "firstName" v-bind:class="[ (errors.firstName ? 'is-invalid' : ''), (typeof(firstName) == 'undefined' ? 'required' : '')]" />
                            <div class="invalid-feedback" v-html="errors.firstName"></div>
                        </div>
                        <div class="form-group col-5">
                            <label>Last Name <span class="required">(Required)</span></label>
                            <Field name="lastName" type="text" class="form-control" v-model = "lastName" v-bind:class="[ (errors.lastName ? 'is-invalid' : ''), (typeof(lastName) == 'undefined' ? 'required' : '')]" />
                            <div class="invalid-feedback" v-html="errors.lastName"></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Date of Birth</label>
                            <Field name="dob" type="date" class="form-control" v-bind:class="[errors.dob ? 'is-invalid' : '']" />
                            <div class="invalid-feedback" v-html="errors.dob"></div>
                        </div>
                        <div class="form-group col">
                            <label>Email</label>
                            <Field name="email" type="text" class="form-control" v-bind:class="[errors.email ? 'is-invalid' : '']" />
                            <div class="invalid-feedback" v-html="errors.email"></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Password</label>
                            <Field name="password" type="password" class="form-control" v-bind:class="[errors.password ? 'is-invalid' : '']" @input="password_check" v-model="message" />
                           
                            <div class="invalid-feedback" v-html="errors.password"></div>
                            <p class="frmvalidation text-red" :class="{'frmvalidation-passed' : message.length > 12}"><i class="fa" :class="message.length > 12 ? 'hide' : 'fa-times'"></i> Minimum 12 characters long</p>
                            
                        </div>
                        <div class="form-group col">
                            <label>Confirm Password</label>
                            <Field name="confirmPassword" type="password" class="form-control" v-bind:class="[errors.confirmPassword ? 'is-invalid' : '']" />
                            <div class="invalid-feedback" v-html="errors.confirmPassword"></div>
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <Field name="acceptTerms" type="checkbox" id="acceptTerms" value="true" class="form-check-input" v-bind:class="[errors.acceptTerms ? 'is-invalid' : '']" />
                        <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                        <div class="invalid-feedback" v-html="errors.acceptTerms"></div>
                    </div>
                    <div class="form-group">
                       <button type="submit" class="btn btn-primary mr-1">Register</button>

                       <button type="button" class="btn btn-primary mr-1"  v-on:click="myFunction()">Show Submit Success Alert</button>

                       
                    </div>
                </Form>
            </div>
        </div>   
    </div> 
    <!--toast Message-->
       <div id="snackbar" class="alert alert-success" :class="[ showSnakeBarMessage == true ? 'showtoast' : '']">
        <div class="message-container"><div class="icon"><i class="icon fa fa-check-circle"></i></div><div class="message"><div> Agent Email is sent successfully. </div></div></div>
       </div>
    <!-- toast message-->
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
export default {
    components: {
        Form,
        Field,
    },
    data() {
        return {
          showSnakeBarMessage:false,
            message:       '',
            has_number:    false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false
        };
    },
    setup() {
        const schema = Yup.object().shape({
            title: Yup.string()
                .required('<span>Title is required</span>'),
            firstName: Yup.string()
                .required('<span>First Name is required</span>'),
            lastName: Yup.string()
                .required('<span>Last name is required</span>'),
            dob: Yup.string()
                .required('<span>Date of Birth is required</span>')
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
            email: Yup.string()
                .required('<span>Email is required</span>')
                .email('<span>Email is invalid</span>'),
            password: Yup.string()
               /* .min(6, '<span>Password must be at least 6 characters</span>')*/
                .required('<span>Password is required</span>'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match</span>')
                .required('<span>Confirm Password is required</span>'),
            acceptTerms: Yup.string()
                .required('<span>Accept Ts & Cs is required</span>')
        });
        const onSubmit = values => {
           
        }
        return {
            schema,
            onSubmit
        };
    },
    methods:{
        closeToastMessage: function(){
            this.showSnakeBarMessage  = false;
        },
        myFunction: function(){
            this.showSnakeBarMessage =  true;
            setTimeout(function(){ this.closeToastMessage();}.bind(this), 3000);  
        },
        password_check: function () {
            this.has_number    = /\d/.test(this.message);
            this.has_lowercase = /[a-z]/.test(this.message);
            this.has_uppercase = /[A-Z]/.test(this.message);
            this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.message);
        }
    }  
}
</script>